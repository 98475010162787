<template>
  <div>
    <!-- List of stores for users Admin and Establishment Admin-->
    <b-row
      v-if="userData.role_name !== 'establishment_presale_clerk'"
      class="mt-3 match-height"
    >
      <b-col class="stores-grid">
        <b-card
          v-for="store in establishment.stores_attributes"
          :key="store.id"
          class="store-card"
          @click="handleShowStoreModal(store)"
        >
          <div class="mt-1 text-center">
            <div class="d-flex justify-content-center mt--5">
              <span @click="handleLogoRedirect(store.id)">
                <b-avatar
                  :src="`${store.logo}`"
                  size="60"
                  class="cursor-pointer"
                />
              </span>
            </div>

            <!-- Dropdown -->
            <div class="dropdown settings-btn">
              <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'walleat-add-store-credit',
                    params: { store_id: store.id },
                  }"
                >
                  Añadir saldo a la tienda
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{ name: 'store-users', params: { id: store.id } }"
                >
                  Gestionar usuarios
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{
                    name: 'store-suppliers',
                    params: {
                      establishment_id: $route.params.id,
                      store_id: store.id,
                    },
                  }"
                >
                  Gestionar proveedores
                </b-dropdown-item>
                <b-dropdown-item @click.stop="handleShareStoreModal(store.id)">
                  Compartir tienda
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item
                  :to="{
                    name: 'edit-store',
                    params: { id: $route.params.id, store_id: store.id },
                  }"
                >
                  Editar tienda
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{
                    name: 'edit-store-csd',
                    params: { id: $route.params.id, store_id: store.id },
                  }"
                >
                  Gestionar CSD
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <h5 class="store-name">
              {{ store.name }}
            </h5>
            <div class="d-flex align-items-center justify-content-center mb-1">
              <b-badge variant="light-secondary">
                <feather-icon icon="BoxIcon" class="mr-1" />
                {{ store.store_products_count }}
              </b-badge>
            </div>
          </div>
        </b-card>
        <b-card
          v-if="
            userData.role_name === 'admin' ||
            userData.role_name === 'establishment_admin'
          "
          class="store-card"
        >
          <div
            class="text-center"
            @click="
              $router.push({
                name: 'new-store',
                params: { id: $route.params.id },
              })
            "
          >
            <div class="d-flex justify-content-center mb-1">
              <b-avatar text="+" size="3rem" variant="success" />
            </div>
            <div class="establishment-name">Agregar</div>
            <div class="establishment-name">Tienda</div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- List of stores for users Establishment Presale Clerk-->
    <b-row
      v-if="userData.role_name === 'establishment_presale_clerk'"
      class="mt-3 match-height"
    >
      <b-col
        v-for="store in filteredStores"
        :key="store.id"
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="4"
      >
        <b-card v-if="store.category !== 'physical'" class="mt-1 text-center">
          <div
            class="d-flex justify-content-center mt--5 mb-1 position-relative"
          >
            <span @click="handleLogoRedirect(store.id)">
              <b-avatar
                :src="`${store.logo}`"
                size="85"
                class="cursor-pointer"
              />
            </span>
          </div>

          <h5 class="store-name">
            {{ store.name }}
          </h5>
          <div class="d-flex align-items-center justify-content-center">
            <b-badge variant="light-secondary">
              <feather-icon icon="BoxIcon" class="mr-1" />
              {{ store.store_products_count }}
            </b-badge>
          </div>

          <b-button
            v-if="
              store.store_products_count > 0 &&
              userData.role_name === 'establishment_presale_clerk'
            "
            block
            class="custom-btn custom-btn-color"
            :to="{ name: 'e-commerce-presale', params: { store_id: store.id } }"
          >
            <i class="fas fa-truck"></i>
            Realizar Preventa
          </b-button>

          <b-button
            v-if="store.store_products_count > 0"
            block
            class="custom-btn custom-btn-color"
            :to="{ name: 'online-sales-view', params: { id: store.id } }"
          >
            <i class="fas fa-list"></i>
            Preventas realizadas
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="shareStoreModal" hide-footer size="xl">
      <b-card no-body>
        <div class="m-2">
          <b-row class="justify-content-center">
            <b-col cols="6" class="text-center">
              <h3>Compartir tienda</h3>
              <qriously :value="qrcodeValue" :size="200" />
              <b-button
                variant="success"
                @click="shareOnSocialMedia"
                v-if="webShare"
              >
                Compartir por redes sociales
              </b-button>
              <div v-if="!webShare">
                <b-label>Correo electrónico:</b-label>
                <b-form-input
                  id="emailToShare"
                  v-model="emailToShare"
                  required
                  class="my-1 w-50 mx-auto"
                />
                <a :href="generateMailtoLink()">Enviar correo</a>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-modal>

    <store-modal :store="selectedStore" :establishment="establishment" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  BCard,
  BRow,
  BCol,
  BModal,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BButton,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VueQriously from "vue-qriously";
import StoreModal from "./StoreModal.vue";

export default {
  directives: {
    Ripple,
    VueQriously,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BModal,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButton,
    BBadge,
    StoreModal,
  },
  data() {
    return {
      establishment: {},
      userData: JSON.parse(localStorage.getItem("userData")),
      qrcodeValue: "",
      webShare: navigator.canShare,
      emailToShare: "",
      selectedStore: {},
    };
  },

  mounted() {
    this.fetchEstablishment(this.$route.params.id).then((response) => {
      this.establishment = response;
    });
  },
  computed: {
    filteredStores() {
      return this.establishment.stores_attributes.filter(
        (store) => store.category !== "physical"
      );
    },
  },
  methods: {
    ...mapActions("establishments", ["fetchEstablishment"]),
    ...mapActions("stores", ["fetchReferralToken"]),
    handleLogoRedirect(storeId) {
      // if(this.establishment.establishment_type !== 'corporate_education') {
      //   this.$router.push({ name: 'POS', params: { store_id: storeId } })
      // }
    },
    handleShareStoreModal(storeId) {
      this.$bvModal.show("shareStoreModal");
      this.fetchReferralToken({ storeId: storeId }).then((response) => {
        const encodedToken = encodeURIComponent(response.token);
        this.qrcodeValue =
          "https://mywalleat.com/register-referral?token=" + encodedToken;
      });
    },
    handleShowStoreModal(store) {
      this.$bvModal.show("storeModal");
      this.selectedStore = store;
    },
    async shareOnSocialMedia() {
      if (!navigator.canShare) {
        console.warn(
          "La API de Web Share no está disponible en este navegador."
        );
        return;
      }

      const shareData = {
        title: "Walleat",
        text: "Anéxate a esta tienda",
        url: this.qrcodeValue,
        //files: [file],
      };

      try {
        await navigator.share(shareData);
      } catch (err) {
        console.error(err);
      }
    },
    generateMailtoLink() {
      const encodedToken = encodeURIComponent(this.qrcodeValue);
      return `mailto:${this.emailToShare}?subject=Anexate a esta tienda&body=Hola, te comparto este link para que te anexes a esta tienda: localhost:8081/register-referral?token=${encodedToken}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.mt--5 {
  margin-top: -3rem !important;
}

.establishment-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.3rem;
  font-weight: 600;
}

.settings-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.stores-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  grid-row: 1fr;

  @media (min-width: 350px){
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1536px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1800px) {
    grid-template-columns: repeat(7, 1fr);
  }

  @media (min-width: 2000px) {
    grid-template-columns: repeat(8, 1fr);
  }

  .store-card {
    position: relative;
    cursor: pointer;
  }
}
</style>
