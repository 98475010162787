var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userData.role_name !== 'establishment_presale_clerk')?_c('b-row',{staticClass:"mt-3 match-height"},[_c('b-col',{staticClass:"stores-grid"},[_vm._l((_vm.establishment.stores_attributes),function(store){return _c('b-card',{key:store.id,staticClass:"store-card",on:{"click":function($event){return _vm.handleShowStoreModal(store)}}},[_c('div',{staticClass:"mt-1 text-center"},[_c('div',{staticClass:"d-flex justify-content-center mt--5"},[_c('span',{on:{"click":function($event){return _vm.handleLogoRedirect(store.id)}}},[_c('b-avatar',{staticClass:"cursor-pointer",attrs:{"src":("" + (store.logo)),"size":"60"}})],1)]),_c('div',{staticClass:"dropdown settings-btn"},[_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","toggle-class":"p-0","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                  name: 'walleat-add-store-credit',
                  params: { store_id: store.id },
                }}},[_vm._v(" Añadir saldo a la tienda ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'store-users', params: { id: store.id } }}},[_vm._v(" Gestionar usuarios ")]),_c('b-dropdown-item',{attrs:{"to":{
                  name: 'store-suppliers',
                  params: {
                    establishment_id: _vm.$route.params.id,
                    store_id: store.id,
                  },
                }}},[_vm._v(" Gestionar proveedores ")]),_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.handleShareStoreModal(store.id)}}},[_vm._v(" Compartir tienda ")]),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"to":{
                  name: 'edit-store',
                  params: { id: _vm.$route.params.id, store_id: store.id },
                }}},[_vm._v(" Editar tienda ")]),_c('b-dropdown-item',{attrs:{"to":{
                  name: 'edit-store-csd',
                  params: { id: _vm.$route.params.id, store_id: store.id },
                }}},[_vm._v(" Gestionar CSD ")])],1)],1),_c('h5',{staticClass:"store-name"},[_vm._v(" "+_vm._s(store.name)+" ")]),_c('div',{staticClass:"d-flex align-items-center justify-content-center mb-1"},[_c('b-badge',{attrs:{"variant":"light-secondary"}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"BoxIcon"}}),_vm._v(" "+_vm._s(store.store_products_count)+" ")],1)],1)])])}),(
          _vm.userData.role_name === 'admin' ||
          _vm.userData.role_name === 'establishment_admin'
        )?_c('b-card',{staticClass:"store-card"},[_c('div',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
              name: 'new-store',
              params: { id: _vm.$route.params.id },
            })}}},[_c('div',{staticClass:"d-flex justify-content-center mb-1"},[_c('b-avatar',{attrs:{"text":"+","size":"3rem","variant":"success"}})],1),_c('div',{staticClass:"establishment-name"},[_vm._v("Agregar")]),_c('div',{staticClass:"establishment-name"},[_vm._v("Tienda")])])]):_vm._e()],2)],1):_vm._e(),(_vm.userData.role_name === 'establishment_presale_clerk')?_c('b-row',{staticClass:"mt-3 match-height"},_vm._l((_vm.filteredStores),function(store){return _c('b-col',{key:store.id,attrs:{"cols":"12","sm":"6","md":"6","lg":"6","xl":"4"}},[(store.category !== 'physical')?_c('b-card',{staticClass:"mt-1 text-center"},[_c('div',{staticClass:"d-flex justify-content-center mt--5 mb-1 position-relative"},[_c('span',{on:{"click":function($event){return _vm.handleLogoRedirect(store.id)}}},[_c('b-avatar',{staticClass:"cursor-pointer",attrs:{"src":("" + (store.logo)),"size":"85"}})],1)]),_c('h5',{staticClass:"store-name"},[_vm._v(" "+_vm._s(store.name)+" ")]),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-badge',{attrs:{"variant":"light-secondary"}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"BoxIcon"}}),_vm._v(" "+_vm._s(store.store_products_count)+" ")],1)],1),(
            store.store_products_count > 0 &&
            _vm.userData.role_name === 'establishment_presale_clerk'
          )?_c('b-button',{staticClass:"custom-btn custom-btn-color",attrs:{"block":"","to":{ name: 'e-commerce-presale', params: { store_id: store.id } }}},[_c('i',{staticClass:"fas fa-truck"}),_vm._v(" Realizar Preventa ")]):_vm._e(),(store.store_products_count > 0)?_c('b-button',{staticClass:"custom-btn custom-btn-color",attrs:{"block":"","to":{ name: 'online-sales-view', params: { id: store.id } }}},[_c('i',{staticClass:"fas fa-list"}),_vm._v(" Preventas realizadas ")]):_vm._e()],1):_vm._e()],1)}),1):_vm._e(),_c('b-modal',{attrs:{"id":"shareStoreModal","hide-footer":"","size":"xl"}},[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"6"}},[_c('h3',[_vm._v("Compartir tienda")]),_c('qriously',{attrs:{"value":_vm.qrcodeValue,"size":200}}),(_vm.webShare)?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.shareOnSocialMedia}},[_vm._v(" Compartir por redes sociales ")]):_vm._e(),(!_vm.webShare)?_c('div',[_c('b-label',[_vm._v("Correo electrónico:")]),_c('b-form-input',{staticClass:"my-1 w-50 mx-auto",attrs:{"id":"emailToShare","required":""},model:{value:(_vm.emailToShare),callback:function ($$v) {_vm.emailToShare=$$v},expression:"emailToShare"}}),_c('a',{attrs:{"href":_vm.generateMailtoLink()}},[_vm._v("Enviar correo")])],1):_vm._e()],1)],1)],1)])],1),_c('store-modal',{attrs:{"store":_vm.selectedStore,"establishment":_vm.establishment}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }